import { Grid, Image } from "theme-ui";
import autonetapi from "../../../../content/pages/portfolio/autonetapi.png";
import drop from "../../../../content/pages/portfolio/drop.png";
import alihan from "../../../../content/pages/portfolio/alihan.png";
import unibin from "../../../../content/pages/portfolio/unibin.png";
import * as React from 'react';
export default {
  Grid,
  Image,
  autonetapi,
  drop,
  alihan,
  unibin,
  React
};