import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hello there!
    </Text>
    <p>{`I'm Rafi 👋 - currently working as a Security Engineer 👨‍💻. I love information security and software engineering. Everyday I spend my time coding and playing CTF 🚩. I'd like to post my life experiences, CTF write-ups, and IT-related things here at my blog-site. Thanks for visiting! ✨`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      