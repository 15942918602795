/** @jsx jsx */
import React from "react"
import { jsx, Link as TLink } from "theme-ui"
import useMinimalBlogConfig from "../hooks/use-minimal-blog-config"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faGithub, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'

const HeaderExternalLinks = () => {
  const { externalLinks } = useMinimalBlogConfig()
  library.add(faGithub, faTwitter, faInstagram, faLinkedin)

  return (
    <React.Fragment>
      {externalLinks && externalLinks.length > 0 && (
        <div style={{display: 'flex', justifyContent: 'center'}}>
        <div sx={{ "a:not(:first-of-type)": { ml: 3 }, fontSize: [1, `18px`] }}>
          {externalLinks.map((link) => (
            <TLink key={link.url} href={link.url} target="_blank">
              <FontAwesomeIcon icon={['fab', link.name]} size="lg" />
            </TLink>
          ))}
        </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default HeaderExternalLinks
